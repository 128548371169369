export const translations = {
  app_title: 'Zalando Xmas Web Client',

  index: 'Index',
  avatar_recorder: 'Avatar Recorder',
  karaoke_recorder: 'Karaoke Recorder',
  panorama: 'Panorama',

  record: 'Record',
  record_avatar: 'Record Avatar',
  stop_recording: 'Stop Recording',
  delete_recording: 'Delete Recording',
  pause_recording: 'Pause Recording',
  resume_recording: 'Resume Recording',

  file_fetching: 'File Fetching',

  gallery: 'Gallery',

  chat: 'Chat',

  game: 'Game',

  stream: 'Livestream',
}
export const t = (string) => translations[string] || string

export default translations
