import styled from '@emotion/styled'
import Page from 'components/Page'
import { t } from 'lib/translations'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

const useIndex = () => {
  const dispatch = useDispatch()
  const username = useSelector((state) => state.username)

  return { username }
}

export default function Index() {
  const router = useRouter()
  const { username } = useIndex()
  useEffect(() => {
    if (username) return router.push('/livestream')
    else router.push('/login')
  }, [username, router])

  return null
}

export function getStaticProps() {
  // Note that in this case we're returning the state directly, without creating
  // the store first (like in /pages/ssr.js), this approach can be better and easier
  return {
    props: {
      initialZustandState: {
        chatOpen: true,
        chatDisabled: true,
      },
    },
  }
}

const Title = styled.h1``
const Subtitle = styled.h2``
const PageLinks = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`
const PageLink = styled.a`
  margin: 4rem;
  padding: 0.4rem 1rem;
  border: 1px solid black;
  cursor: pointer;
`
